import React from 'react'
import routes from '../routes'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import Layout from '../ui/layout/layout'
import SEO from '../ui/components/seo/seo'
import Text from '../ui/elements/text/text'
import TextLink from '../ui/elements/text_link/text_link'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './legal.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const LegalPage = ({ location }) => (
	<Layout location={location}>
		<SEO title="Legal Disclaimer" url={routes.legalDisclaimer()} />
		<div className={styles.Wrapper}>
			<Text as="h1" size="56" chunky className={styles.BigTitle}>
				Legal Disclaimer
			</Text>

			<Text as="h2" size="28" chunky className={styles.Title}>
				Blog purpose
			</Text>
			<Text as="p" testament className={styles.Text}>
				This site (blog.edpuzzle.com) (the “Blog”) is owned by EDpuzzle, Inc. (hereinafter “Edpuzzle”, “we”,
				“us”, “our” or the “Company”) and contains articles and posts on educational technology, teaching in
				the 21st century, the teacher lifestyle and free video lesson plans. The primary purpose of the Blog
				is to provide teachers with educational information and resources.
			</Text>

			<Text as="h2" size="28" chunky className={styles.Title}>
				Users and Edpuzzle Terms of Service
			</Text>
			<Text as="p" testament className={styles.Text}>
				By accessing and using the Blog you are granted the condition of “user” and, as such, you acknowledge
				to have been duly informed and to agree to the present Disclaimer and, subsidiarily, to the{' '}
				<TextLink rel="external" testament external trackAnalytics to={routes.termsOfService()}>
					Terms of Service
				</TextLink>{' '}
				and{' '}
				<TextLink rel="external" testament external trackAnalytics to={routes.privacyPolicy()}>
					Privacy Policy
				</TextLink>{' '}
				posted on the Edpuzzle website (www.edpuzzle.com), which shall apply to all matters not covered by
				this Disclaimer.
			</Text>
			<Text as="p" testament className={styles.Text}>
				Notwithstanding the aforementioned, accessing this Blog does not, in any way, represent the start of a
				commercial relationship with Edpuzzle.
			</Text>

			<Text as="h2" size="28" chunky className={styles.Title}>
				Personal information
			</Text>
			<Text as="p" testament className={styles.Text}>
				Please review Edpuzzle’s{' '}
				<TextLink rel="external" testament external trackAnalytics to={routes.privacyPolicy()}>
					Privacy Policy
				</TextLink>{' '}
				to learn how we collect and processes your personal data. Your rights as a data subject are:
			</Text>
			<ul className={styles.List}>
				<Text as="li" testament>
					to be informed about how Edpuzzle uses your personal information;
				</Text>
				<Text as="li" testament>
					to request access to personal information held by Edpuzzle, and to have any incorrect, inaccurate or
					incomplete personal information rectified;
				</Text>
				<Text as="li" testament>
					where appropriate, to restrict processing concerning you or to object to processing;
				</Text>
				<Text as="li" testament>
					to have personal information erased where there is no compelling reason for its continued
					processing; and
				</Text>
				<Text as="li" testament>
					where applicable, to portability of personal data, that is to say, to receive your personal
					information in a structured and commonly used format.
				</Text>
			</ul>
			<Text as="p" testament className={styles.Text}>
				When accessing the Blog and consulting its contents, it is not necessary for you to provide any
				personally identifiable information (PII). The only case in which you may facilitate PII (email
				address) is by subscribing to the Blog’s newsletter. BY PROVIDING THIS INFORMATION YOU ARE CONSENTING
				TO THE COLLECTION AND PROCESSING OF YOUR PERSONAL INFORMATION BY EDPUZZLE, yet only to the extent
				foreseen by this Disclaimer and, subsidiarily, by Edpuzzle’s{' '}
				<TextLink rel="external" testament external trackAnalytics to={routes.termsOfService()}>
					Terms of Service
				</TextLink>{' '}
				and{' '}
				<TextLink rel="external" testament external trackAnalytics to={routes.privacyPolicy()}>
					Privacy Policy
				</TextLink>
				.
			</Text>

			<Text as="h2" size="28" chunky className={styles.Title}>
				Third-party service providers
			</Text>
			<ul className={styles.List}>
				<Text as="li" testament>
					<b>Mailchimp</b> for managing email communications for users subscribed to our Newsletter. Mailchimp
					is a Georgia (U.S.) Limited Liability Company whose legal name is The Rocket Science Group LLC d/b/a
					Mailchimp. You may check{' '}
					<TextLink testament external trackAnalytics to={routes.mailchimpLegal()}>
						their terms and privacy policy
					</TextLink>{' '}
					for further information on this service.
				</Text>
			</ul>

			<Text as="h2" size="28" chunky className={styles.Title}>
				Obligations and commitments of users
			</Text>
			<Text as="p" testament className={styles.Text}>
				By accessing this Blog, the user will be granted access to articles, information and/or services
				(hereinafter, the “contents”). The user hereby commits to use the contents of this site in accordance
				with the laws, in good faith and respecting public order.
			</Text>
			<Text as="p" testament className={styles.Text}>
				Users will be subject to the same obligations and restrictions foreseen in the{' '}
				<TextLink rel="external" testament external trackAnalytics to={routes.termsOfService()}>
					"Use Restrictions"
				</TextLink>{' '}
				section of the Edpuzzle website.
			</Text>
			<Text as="p" testament className={styles.Text}>
				Edpuzzle hereby reserves the right to suppress, not accept or delete any comments and/or contributions
				made to the Blog that are (a) threatening, abusive, violent, or harmful to any person or entity, or
				invasive of another’s privacy; (b) hate speech, discriminating, threatening, or pornographic or
				sexually explicit; incite violence; or contain graphic or gratuitous violence; or (c) contravene this
				Disclaimer or Edpuzzle’s Terms of Service in any way.
			</Text>
			<Text as="p" testament className={styles.Text}>
				EDPUZZLE SHALL NOT BE LIABLE FOR THE OPINIONS SHARED OR MADE PUBLIC BY THE USERS THROUGH THE BLOG BY
				USING THE PARTICIPATION TOOLS AVAILABLE AT EACH MOMENT.
			</Text>

			<Text as="h2" size="28" chunky className={styles.Title}>
				Terms of Use
			</Text>
			<Text as="p" testament className={styles.Text}>
				The terms and conditions applicable to this Blog are ruled by the legislation currently in force, as
				well as by the principle of good faith. The user hereby commits to make a good use of the Blog and not
				to adopt any conduct that goes against the laws or harms the rights and/or interests of other users
				and/or third parties.
			</Text>
			<Text as="p" testament className={styles.Text}>
				Being a user of the Blog means that you acknowledge to have read and to have accepted the present
				Disclaimer, the{' '}
				<TextLink rel="external" testament external trackAnalytics to={routes.termsOfService()}>
					Terms of Service
				</TextLink>{' '}
				of the Edpuzzle website and its{' '}
				<TextLink rel="external" testament external trackAnalytics to={routes.privacyPolicy()}>
					Privacy Policy
				</TextLink>
				. IF YOU DO NOT AGREE WITH THE AFOREMENTIONED CONDITIONS, PLEASE REFRAIN FROM ACCESSING AND USING THE
				BLOG.
			</Text>

			<Text as="h2" size="28" chunky className={styles.Title}>
				Limitation of Liability
			</Text>
			<Text as="p" testament className={styles.Text}>
				EDPUZZLE SHALL NOT BE RESPONSIBLE FOR THE INFORMATION AND CONTENTS AVAILABLE ON FORUMS, SOCIAL MEDIA
				OR ANY OTHER PLATFORM THAT ALLOWS THIRD PARTIES TO PUBLISH CONTENT INDEPENDENTLY FROM THE BLOG.
				NEVERTHELESS, EDPUZZLE HEREBY COMMITS TO DELETE OR, WHERE APPLICABLE, BLOCK OR BAN THOSE CONTENTS
				THAT, PUBLISHED THROUGH THE BLOG, COULD SUPPOSE A BREACH OF NATIONAL OR INTERNATIONAL LAWS,
				THIRD-PARTY RIGHTS, MORALITY AND/OR PUBLIC ORDER.
			</Text>
			<Text as="p" testament className={styles.Text}>
				THE BLOG (AND ANY ASSOCIATED CONTENT) IS PROVIDED ON AN “AS IS” BASIS. EDPUZZLE EXPRESSLY DISCLAIMS
				ALL WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, REGARDING THE CONTENT
				AND FUNCTIONALITY OF THE SITE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF QUALITY,
				ACCURACY, PERFORMANCE, AVAILABILITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR
				NON-INFRINGEMENT. WITHOUT LIMITING THE GENERALITY OF THE AFOREMENTIONED, EDPUZZLE DOES NOT ACKNOWLEDGE
				OR AFFIRM THAT THE BLOG SERVICE WILL MEET YOUR REQUIREMENTS, BE ERROR FREE OR UNINTERRUPTED, THAT ALL
				ERRORS WILL BE CORRECTED OR THAT THE BLOG SERVICES ARE FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS.
			</Text>
			<Text as="p" testament className={styles.Text}>
				UNDER NO LEGAL THEORY, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL EDPUZZLE OR ITS SUCCESSORS,
				AFFILIATES, CONTRACTORS, EMPLOYEES, LICENSORS, PARTNERS OR AGENTS, BE LIABLE TO YOU FOR ANY INDIRECT,
				INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR
				LOSS OF PROFITS, COST OF COVER, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF EDPUZZLE HAS
				BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING FROM OR RELATING TO (i) THIS AGREEMENT; (ii)
				YOUR USE OR THE INABILITY TO USE THE BLOG, CONTENT, OR USER SUBMISSIONS; OR (iii) ANY INTERACTION WITH
				ANY THIRD PARTY THROUGH OR IN CONNECTION WITH THE SERVICE, INCLUDING OTHER USERS.
			</Text>
			<Text as="p" testament className={styles.Text}>
				EDPUZZLE RESERVES THE RIGHT TO UPDATE, MODIFY OR DELETE THE INFORMATION CONTAINED IN THE BLOG, AS WELL
				AS TO CHANGE ITS CONFIGURATION OR PRESENTATION, AT ANY MOMENT AND WITHOUT ASSUMING ANY LIABILITIES IN
				THAT RESPECT.
			</Text>

			<Text as="h2" size="28" chunky className={styles.Title}>
				Intellectual Property and Copyright
			</Text>
			<Text as="p" testament className={styles.Text}>
				Edpuzzle is the sole owner of the rights inherent to the contents published or otherwise made
				available through the Blog, except where otherwise provided by law.
			</Text>
			<Text as="p" testament className={styles.Text}>
				No content published or otherwise made available through the Blog can be reproduced, copied or
				published without the express written consent of Edpuzzle – doing so will be considered a serious
				breach of Edpuzzle’s intellectual property rights. As a consequence, it is hereby expressly prohibited
				to reproduce, distribute or publicly display all or part of the contents of the Blog, with commercial
				purposes or not, using any medium and in any form, without the express written consent of Edpuzzle.
			</Text>
			<Text as="p" testament className={styles.Text}>
				All information provided by users through the Blog, such as comments, suggestions or ideas, shall be
				deemed to have been transferred to Edpuzzle free of charge. PLEASE DO NOT SEND ANYTHING THAT CANNOT BE
				USED IN THIS WAY.
			</Text>
			<Text as="p" testament className={styles.Text}>
				Any services, references to products or contents that are not the property of Edpuzzle are owned by
				their respective proprietors and acknowledged as such by Edpuzzle. Said proprietors shall have the
				right to request the modification or deletion of the information they own.
			</Text>

			<Text as="h2" size="28" chunky className={styles.Title}>
				Supplemental documents
			</Text>
			<Text as="p" testament className={styles.Text}>
				Edpuzzle’s{' '}
				<TextLink rel="external" testament external trackAnalytics to={routes.termsOfService()}>
					Terms of Service
				</TextLink>{' '}
				and{' '}
				<TextLink rel="external" testament external trackAnalytics to={routes.privacyPolicy()}>
					Privacy Policy
				</TextLink>{' '}
				shall be applicable to all matters not covered by this Disclaimer and by accessing and using the Blog
				the user acknowledges to have read and to have accepted the aforementioned documents.
			</Text>
		</div>
	</Layout>
)

export default LegalPage
